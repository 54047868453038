import React, { Component } from "react";
import "./App.css";

class App extends Component {
  constructor() {
    super();
    const uuidv4 = require("uuid/v4");
    const uuidv1 = require("uuid/v1");
    const uuidv0 = "00000000-0000-0000-0000-000000000000";

    this.state = {
      uuidv4: uuidv4(),
      uuidv1: uuidv1(),
      uuidv0: uuidv0
    };
  }

  generateUUID = version => {
    if (version === "4") {
      const uuidv4 = require("uuid/v4");
      this.setState({ uuidv4: uuidv4() });
    } else if (version === "1") {
      const uuidv1 = require("uuid/v1");
      this.setState({ uuidv1: uuidv1() });
    }
  };

  copyTextToClipBoard = version => {
    if (version === "4") {
      navigator.clipboard.writeText(this.state.uuidv4);
    } else if (version === "1") {
      navigator.clipboard.writeText(this.state.uuidv1);
    } else if (version === "0") {
      navigator.clipboard.writeText(this.state.uuidv0);
    }
  };

  render() {
    return (
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <main role="main" className="cover">
          <h1>Online UUID Generator</h1>
        </main>
        <div className="uuid mx-auto">
          <h4>Version 4 UUID</h4>
          <h3>
            {this.state.uuidv4}
            <button
              type="button"
              className="btn btn-primary font-weight-bold border-black bg-black"
              onClick={() => this.copyTextToClipBoard("4")}
            >
              Copy
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bold border-black bg-black"
              onClick={() => this.generateUUID("4")}
            >
              Generate
            </button>
          </h3>
          <p>
            A Version 4 UUID is a universally unique identifier that is
            generated using random numbers. The Version 4 UUIDs produced by this
            site were generated using a secure random number generator.
          </p>
        </div>
        <div className="uuid mx-auto">
          <h4>Version 1 UUID</h4>
          <h3>
            {this.state.uuidv1}
            <button
              type="button"
              className="btn btn-primary font-weight-bold border-black bg-black"
              onClick={() => this.copyTextToClipBoard("1")}
            >
              Copy
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bold border-black bg-black"
              onClick={() => this.generateUUID("1")}
            >
              Generate
            </button>
          </h3>
          <p>
            A Version 1 UUID is a universally unique identifier that is
            generated using a timestamp and the MAC address of the computer on
            which it was generated.
          </p>
        </div>
        <div className="uuid mx-auto">
          <h4>Nil/Empty UUID</h4>
          <h3>
            {this.state.uuidv0}
            <button
              type="button"
              className="btn btn-primary font-weight-bold border-black bg-black"
              onClick={() => this.copyTextToClipBoard("0")}
            >
              Copy
            </button>
          </h3>
        </div>
        <footer className="mastfoot mt-auto">
          <p>
            The UUIDs generated by this site are provided AS IS without warranty
            of any kind, not even the warranty that the generated UUIDs are
            actually unique. You are responsible for using the UUIDs and assume
            any risk inherent to using them.
          </p>
        </footer>
      </div>
    );
  }
}

export default App;
